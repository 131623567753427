import { RestaurantAreas } from '~/lib/interfaces/restaurant'
import {
  SmartCardState,
  SmartCardStatus,
  StoreOrder,
  StoreOrderStatus,
  StoreOrderSubstatus,
} from '~/lib/services/store/order/order.dto'
import { SpecialPaymentType } from '~/lib/services/store/payment/payment.dtos'

export const isMOPOrder = (order: StoreOrder) => order.type === RestaurantAreas.MOP

export const isPaymentPending = (order: StoreOrder) =>
  order.status === StoreOrderStatus.PAYMENT_PENDING

export const isPaymentDone = (order: StoreOrder) => order.status === StoreOrderStatus.PAYMENT_DONE

export const canPayOrder = (order: StoreOrder) => {
  const pendingPayment = isPaymentPending(order)
  // special payment
  if (
    pendingPayment &&
    order.paymentMethod === SpecialPaymentType.IN_PERSON &&
    order.subStatus === StoreOrderSubstatus.PAYMENT_PENDING_COUNTER
  )
    return false
  return pendingPayment
}

export const canPickupSpecialOrder = (order: StoreOrder) => {
  // special payment
  if (
    isPaymentPending(order) &&
    order.paymentMethod === SpecialPaymentType.IN_PERSON &&
    order.subStatus === StoreOrderSubstatus.PAYMENT_PENDING_COUNTER
  )
    return true
  return false
}

export const isAreaSelectable = (order: StoreOrder) =>
  isMOPOrder(order) && (isPaymentDone(order) || canPickupSpecialOrder(order)) && order.canPickup

export const getTrackingUrl = (order: StoreOrder): string => order.deliveryPos?.trackingUrl || ''
export const isTrackable = (order: StoreOrder): boolean =>
  !!getTrackingUrl(order) &&
  (order.status === StoreOrderStatus.FLEX_DELIVERING ||
    order.status === StoreOrderStatus.FLEX_READY)

export const getRateUrl = (order: StoreOrder): string => order.webRateUrl || ''
export const isRateable = (order: StoreOrder): boolean =>
  !!getRateUrl(order) && order.status === StoreOrderStatus.FLEX_DELIVERED

export const isReceiveInProgress = (order: StoreOrder) =>
  !isMOPOrder(order) &&
  order.orderMcDia &&
  (
    [
      StoreOrderSubstatus.PAYMENT_DONE_MCDIA,
      StoreOrderSubstatus.PAYMENT_DONE_MCDIA_3DAYS,
      StoreOrderSubstatus.PAYMENT_DONE_MCDIA_INDATE,
    ] as string[]
  ).includes(order.subStatus as string)

export const canReceive = (order: StoreOrder) =>
  !isMOPOrder(order) &&
  order.orderMcDia &&
  order.subStatus === StoreOrderSubstatus.PAYMENT_DONE_MCDIA_INDATE

export const getOrderState = (order: StoreOrder) => {
  return {
    status: order.status,
    isMOP: isMOPOrder(order),
    isPending: canPayOrder(order),
    isPaymentDone: isPaymentDone(order),
    isReceiveInProgress: isReceiveInProgress(order),
    canPickup: isAreaSelectable(order),
    canCancel: (isPaymentDone(order) || isPaymentPending(order)) && order.canCancel,
    canRepeat: order.canRepeat,
    canReceive: canReceive(order),
    isTrackable: isTrackable(order),
    isRateable: isRateable(order),
  }
}

export const getSmartcardKey = (order?: StoreOrder): SmartCardState | undefined => {
  if (!order) return SmartCardStatus.MULTIPLE

  const state = getOrderState(order)

  switch (true) {
    // special case: inPerson ('PAYMENT_PENDING')
    case order.paymentMethod === SpecialPaymentType.IN_PERSON &&
      isPaymentPending(order) &&
      order.subStatus === StoreOrderSubstatus.PAYMENT_PENDING_COUNTER:
      return SmartCardStatus.PENDING_PAYMENT_COUNTER
    // DLV advance sales (inside 3 days before date)
    case !isMOPOrder(order) && order.subStatus === StoreOrderSubstatus.PAYMENT_DONE_MCDIA_3DAYS:
      return SmartCardStatus.PAYMENT_DONE_MCDIA_3DAYS
    // DLV advance sales (IN_DATE)
    case !isMOPOrder(order) && order.subStatus === StoreOrderSubstatus.PAYMENT_DONE_MCDIA_INDATE:
      return SmartCardStatus.PAYMENT_DONE_MCDIA_INDATE
    case state.isPending:
      return SmartCardStatus.PENDING
    case state.isPaymentDone:
      return SmartCardStatus.AFTER_PAYMENT
    case ([StoreOrderStatus.FLEX_RECEIVED, StoreOrderStatus.FLEX_ACCEPTED] as string[]).includes(
      order.status
    ):
      // special case: inPerson (ACCEPTED/RECEIVED) || other payment methods
      return order.paymentMethod === SpecialPaymentType.IN_PERSON &&
        order.subStatus === StoreOrderSubstatus.PAYMENT_PENDING_COUNTER
        ? SmartCardStatus.ACCEPTED_PAYMENT_COUNTER
        : SmartCardStatus.ACCEPTED
    case order.status === StoreOrderStatus.FLEX_PREPARING ||
      order.status === StoreOrderStatus.FLEX_STAGING:
      return SmartCardStatus.PREPARING
    case order.status === StoreOrderStatus.FLEX_READY:
      return SmartCardStatus.READY
    case order.status === StoreOrderStatus.FLEX_DELIVERING:
      return SmartCardStatus.DELIVERING
    case order.status === StoreOrderStatus.FLEX_DELIVERED:
      return SmartCardStatus.DELIVERED
    default:
      return undefined
  }
}

export const mustNotShowMcDiaMessageOrder = (order: StoreOrder) =>
  (
    [
      StoreOrderStatus.FLEX_CANCELLED,
      StoreOrderStatus.CANCELLED,
      StoreOrderStatus.FINISHED,
    ] as string[]
  ).includes(order.status)

export const mustShowYappyMessageOrder = (order: StoreOrder) => {
  const paymentType = order.paymentMethod.toLowerCase()
  if (paymentType !== 'yappy') return false
  return !(
    [
      StoreOrderStatus.VALIDATION_PENDING,
      StoreOrderStatus.PAYMENT_PENDING,
      StoreOrderStatus.FLEX_DELIVERED,
      StoreOrderStatus.FINISHED,
      StoreOrderStatus.FLEX_CANCELLED,
      StoreOrderStatus.CANCELLED,
    ] as string[]
  ).includes(order.status)
}
