import { ComposerTranslation } from 'vue-i18n'
import { StoreErrorType } from '~/lib/models/errors'
import { GTMContentGroup } from '~/lib/models/gtm'
import { RepeatOrderState, RepeatOrderStatus } from '~/lib/models/repeat-order'
import { ReceiveOrderError, RepeatOrderDto } from '~/lib/services/store/order/order.dto'

export const useOrderActions = () => {
  /* CancelOrder */
  const cancelOrder = async (
    orderData: { id: string; isPaymentPending: boolean },
    onCancelSuccess?: Function,
    onCancelError?: Function
  ) => {
    const authStore = useAuthStore()
    const { handleStoreError } = useStoreResponse()
    const { trackRefund, trackOrderCancelledBefore } = useGTM()
    const { orderServiceRef } = useStoreService()
    if (!authStore.token) return
    try {
      const orderService = orderServiceRef()
      const { id, isPaymentPending } = orderData
      const order = await orderService.cancelOrder({
        customerToken: authStore.token,
        countryCode: useCountry(),
        orderId: id,
      })

      if (!isPaymentPending) trackRefund(order)
      else trackOrderCancelledBefore()

      if (onCancelSuccess) onCancelSuccess()
    } catch (error) {
      const { key } = handleStoreError(error)
      if (key === StoreErrorType.status401) await navigateTo('/')
      else if (onCancelError) onCancelError(key, error)
    }
  }

  /* RepeatOrder */
  const repeatOrder = async (
    orderId: string
  ): Promise<{ status: RepeatOrderState; response?: RepeatOrderDto }> => {
    const authStore = useAuthStore()
    const { handleRepeatStatus } = useStoreResponse()
    const { orderServiceRef } = useStoreService()
    if (!authStore.token) return { status: RepeatOrderStatus.GENERIC_ERROR, response: undefined }
    try {
      const orderService = orderServiceRef()
      const repeatResponse = await orderService.repeatOrder({
        customerToken: authStore.token,
        countryCode: useCountry(),
        orderId,
      })
      return { status: RepeatOrderStatus.SUCCESS, response: repeatResponse }
    } catch (error) {
      const status = handleRepeatStatus(error)
      return { status, response: undefined }
    }
  }

  /* ReceiveOrder */
  const receiveOrder = async (
    dialog: any, // type this,
    t: ComposerTranslation,
    addressString: string,
    orderId: string
  ) => {
    const authStore = useAuthStore()
    const { handleStoreError } = useStoreResponse()
    const { orderServiceRef } = useStoreService()
    const { trackPageView, trackDeclineGetPreOrder, trackGetPreOrder, trackOnCloseModal } = useGTM()
    if (!authStore.token) return { shouldContinue: false }
    try {
      trackPageView(
        {
          cg: GTMContentGroup.primary.ECOMMERCE,
          cg2: GTMContentGroup.secondary.ORDERS,
          cg3: GTMContentGroup.tertiary.PRE_ORDER_CONFIRMATION,
        },
        true
      )
      // dialog confirmation
      const result = await showDialogAlert({
        dialog,
        data: {
          title: t('orders.alert.receiveOrder.title'),
          body: t('orders.alert.receiveOrder.body', { address: addressString }),
          btnSecondary: t('orders.alert.receiveOrder.cancel'),
          btnPrimary: t('orders.alert.receiveOrder.accept'),
          isCloseHidden: true,
          // analytics
          preOrder: true,
        },
      })

      if (!result) {
        trackDeclineGetPreOrder()
        trackOnCloseModal()
        return { shouldContinue: false }
      }

      trackGetPreOrder()
      const orderService = orderServiceRef()
      await orderService.requestReceiveOrder({
        customerToken: authStore.token,
        countryCode: useCountry(),
        orderId,
      })
      trackOnCloseModal()
      return { shouldContinue: true }
    } catch (error) {
      const { status } = handleStoreError(error)
      let receiveOrderError: ReceiveOrderError = 'generic'
      switch (true) {
        case status === 613:
          receiveOrderError = 'no-riders'
          break
        case status === 601:
          receiveOrderError = 'no-stock'
          break
        case [605, 618].includes(status):
          receiveOrderError = 'rest-closed'
          break
      }
      return { shouldContinue: false, receiveOrderError }
    }
  }

  return {
    cancelOrder,
    repeatOrder,
    receiveOrder,
  }
}
